


























































































































































































































import { useHomeBannerData } from '~/components/HomePage/homeBanner';
import SkeletonLoader from '~/components/SkeletonLoader/index.vue';

import { defineComponent, ref, useContext, onMounted, useFetch, computed, useRouter } from '@nuxtjs/composition-api';
import { SfCarousel, SfLink, SfScrollable, SfAlert, SfTabs } from '@storefront-ui/vue';
import LazyHydrate from 'vue-lazy-hydration';
import { useCache, CacheTagPrefix } from '@vue-storefront/cache';
import { SfBanner, SfBannerGrid } from '@storefront-ui/vue';
import { CmsPage } from '~/modules/GraphQL/types';
import HeroSection from '~/components/HeroSection.vue';
import { getMetaInfo } from '~/helpers/getMetaInfo';
import { useContent } from '~/composables';
import LoadWhenVisible from '~/components/utils/LoadWhenVisible.vue';
import { useHomePageData } from '~/components/HomePage/homePage';
import { useNewGearItems } from '~/components/HomePage/newGearItem';
import { useSweetDeals } from "~/components/HomePage/sweetDeals";
import { useOpenBoxByCategory } from "~/components/HomePage/openBoxItem";
import { useFeaturedProductsByCategory } from '~/components/czar/homeFeaturedProducts/homeFeaturedProducts';
import { useCart } from '~/modules/checkout/composables/useCart';
import priceBox from '~/components/HomePage/priceBox.vue';
import customProductCard from '~/components/czar/customProductCard/customProductCard.vue';
import customProductCard1 from '~/components/czar/customProductCard/customProductCard1.vue';
import mainBanner from '~/components/czar/homePage/mainBanner.vue';
import mainBannerDemo from '~/components/czar/homePage/mainBannerDemo.vue';
import { storeToRefs } from 'pinia';
export default defineComponent({
  name: 'HomePage',
  components: {
    customProductCard,
    customProductCard1,
    SfTabs,
    HeroSection,
    LazyHydrate,
    LoadWhenVisible,
    SfBanner,
    SfBannerGrid,
    SfCarousel,
    SfScrollable,
    SfLink,
    SfAlert,
    CallToAction: () => import(/* webpackPrefetch: true */ '~/components/CallToAction.vue'),
    InstagramFeed: () => import(/* webpackPrefetch: true */ '~/components/InstagramFeed.vue'),
    MobileStoreBanner: () => import(/* webpackPrefetch: true */ '~/components/MobileStoreBanner.vue'),
    NewProducts: () => import(/* webpackPrefetch: true */ '~/components/NewProducts.vue'),
    priceBox,
    mainBanner,
    mainBannerDemo,
    SkeletonLoader
  },

  setup() {
    const context = useContext();
    // @ts-ignore
    const { imageProvider, magentoBaseUrl } = context.$vsf.$magento.config;
    const homeBannerSection = useHomeBannerData();
    const { sliders } = storeToRefs(homeBannerSection);


    const { all_data } = useHomePageData();
    const { app: { i18n } } = useContext();
    const { addTags } = useCache();
    const { loadPage } = useContent();
    const { app } = useContext();
    const year = new Date().getFullYear();
    const { isDesktop } = app.$device;
    
    /** get newGearItems data */
    const newGearItems = useNewGearItems();
    const { data } = storeToRefs(newGearItems);
    /** get sweetDealsItems data */
    const sweetDealsItems = useSweetDeals();
    const { sweetDealsData } = storeToRefs(sweetDealsItems);
    /** get usedGearItems data */
    const usedGearItems = useFeaturedProductsByCategory();
    var queryVariables = { category_id: "1207" };
    usedGearItems.fetchUsedGear(queryVariables);
    const { usedGearData } = storeToRefs(usedGearItems);
    /** get openBoxItems data */
    const openBoxItems = useOpenBoxByCategory();
    var queryVariables = { category_id: "1259" };
    openBoxItems.fetchUsedGear(queryVariables);
    const { openBoxData } = storeToRefs(openBoxItems);

    var slider_loading = ref(true);

    const initFun = () => {
      slider_loading.value = false;
    }

    const main_categories = [
      {
          "title": "Cameras",
          "url": "/photography/cameras-lenses/digital-cameras.html",
          "custom_class": "Photography-image",
          "__typename": "mainCategories"
      },
      {
          "title": "Lenses",
          "url": "/photography/cameras-lenses/lenses.html",
          "custom_class": "lenses-image",
          "__typename": "mainCategories"
      },
      {
          "title": "Accessories",
          "url": "photography/accessories.html",
          "custom_class": "Photo-image",
          "__typename": "mainCategories"
      },
      {
          "title": "Lighting",
          "url": "lighting",
          "custom_class": "lighting-image",
          "__typename": "mainCategories"
      },
      {
          "title": "Used",
          "url": "shop-used-gear",
          "custom_class": "Used-image",
          "__typename": "mainCategories"
      },
      {
          "title": "Audio",
          "url": "audio",
          "custom_class": "audio-image",
          "__typename": "mainCategories"
      },
      {
          "title": "Video",
          "url": "video",
          "custom_class": "Video-image",
          "__typename": "mainCategories"
      },
      {
          "title": "Film",
          "url": "/photography/film-darkroom-supplies/film.html",
          "custom_class": "film-image",
          "__typename": "mainCategories"
      },
      {
          "title": "Printers",
          "url": "/photography/scanning-printing/photo-printers.html",
          "custom_class": "printer-image",
          "__typename": "mainCategories"
      }
  ];
  const second_slider = 
  [
    {
      "title": "Block01",
      "url": "#",
      "image_url": magentoBaseUrl+"/media/weltpixel/owlcarouselslider/images/b/l/block01.jpg",
      "__typename": "bannerData"
    },
    {
      "title": "Block02",
      "url": null,
      "image_url": magentoBaseUrl+"/media/weltpixel/owlcarouselslider/images/b/l/block02.jpg",
      "__typename": "bannerData"
    },
    {
      "title": "Block03",
      "url": "#",
      "image_url": magentoBaseUrl+"/media/weltpixel/owlcarouselslider/images/b/l/block03.jpg",
      "__typename": "bannerData"
    }
  ];
    
    const page = ref<CmsPage | null>(null);
    const router = useRouter();
    var valid_msg_error = '';
    const hero = ref({
      title: app.i18n.t('Colorful summer dresses are already in store'),
      subtitle: app.i18n.t('SUMMER COLLECTION {year}', { year }),
      buttonText: app.i18n.t('Learn more'),
      imageSrc: '/homepage/bannerB.webp',
      imageWidth: isDesktop ? 1240 : 328,
      imageHeight: isDesktop ? 400 : 224,
      imageConfig: {
        fit: 'cover',
        format: 'webp',
      },
      link: '/women.html',
    });
    const { addItem, error: cartError, addedtocart_id: addedtocart_id, loading: isCartLoading, canAddToCart } = useCart();
    const addToCartError = computed(() => cartError.value?.addItem?.message);
    const callToAction = ref({
      title: app.i18n.t('Subscribe to Newsletters'),
      description: app.i18n.t('Be aware of upcoming sales and events. Receive gifts and special offers!'),
      buttonText: app.i18n.t('Subscribe'),
      imageSrc: '/homepage/newsletter.webp',
      imageWidth: isDesktop ? 1240 : 400,
      imageHeight: isDesktop ? 202 : 200,
      imageConfig: {
        fit: 'cover',
        format: 'webp',
      },
    });
    const add_type_product = (product) => {
      product.__typename = product.typename;
      return product;
    }

    useFetch(async () => {
      page.value = await loadPage({ identifier: 'home' });
    });

    onMounted(() => {
      addTags([{ prefix: CacheTagPrefix.View, value: 'home' }]);
    });

    const get_slider_by_title = (sliders, title) => {
      var banner = [];
      for (const property in sliders) {
        if (sliders[property]['title'] == title) {
          banner = sliders[property]['banners'];
        }
      }
      return banner;
    }


    const activeTabChanged = (newActiveTab) => {
      const customAttribute = newActiveTab.title;      
      return customAttribute;
    };
    
    

    /* const sweet_deals = ref([]);
    const used_gear = ref([]);
    const open_box = ref([]); */
    const loading = ref(false);
    const changeTab = (tabNumber: number) => {
      if (tabNumber == 1) {
        loading.value = true;
        if (process.client) {
          setTimeout(function(){
            loading.value = false;
            window.dispatchEvent(new Event('resize'));
          },0.5);
        }
      }
      if (tabNumber == 2) {
        loading.value = true;
        if (process.client) {
          setTimeout(function(){
            loading.value = false;
            window.dispatchEvent(new Event('resize'));
          },0.5);
        }
      }
      if (tabNumber == 3) {
        loading.value = true;
        if (process.client) {
          setTimeout(function(){
            loading.value = false;
            window.dispatchEvent(new Event('resize'));
          },0.5);
        }
      }
      if (tabNumber == 4) {
        loading.value = true;
        if (process.client) {
          setTimeout(function(){
            loading.value = false;
            window.dispatchEvent(new Event('resize'));
          },0.5);
        }
          /* var queryVariables = {
            category_id: all_data.value.homeSection[0].featured_items.tab4.data
          }
          const all_dataf = useFeaturedProducts(queryVariables);
          // @ts-ignore
          open_box.value = all_dataf; */
      }
      /* activeTabChanged(tabNumber); */
      return tabNumber;
    };
    
    const customactivetab = ref(1);
    const activeTab = computed((tab) => {
      return customactivetab.value;
    });
    const tab_nav_clicked = (tab) => {
      customactivetab.value = tab;
    };

    return {
      customactivetab,
      all_data,
      tab_nav_clicked,
      get_slider_by_title,
      activeTabChanged,
      changeTab,      
      activeTab,
      add_type_product,
      /* banners, */
      callToAction,
      hero,
      page,
      
      /* sweetDealProduct, */
      addItem,
      addToCartError,
      isCartLoading,
      addedtocart_id,
      /* sweet_deals,
      used_gear,
      open_box, */
      /* main_slider, */
      second_slider,
      main_categories,
      data,
      sweetDealsData,
      usedGearData,
      openBoxData,
      loading,
      sliders,
      slider_loading,
      initFun
    };
  },
  head() {
    
    return {
      'title':'Midwest Photo - Capture. Inspire. Share.'
    };
  },
});
